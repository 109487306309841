import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 14 15" {...props}>
      <path
        d="M6.33398 9.50016H7.66732V10.8335H6.33398V9.50016ZM6.33398 4.16683H7.66732V8.16683H6.33398V4.16683ZM6.99398 0.833496C3.31398 0.833496 0.333984 3.82016 0.333984 7.50016C0.333984 11.1802 3.31398 14.1668 6.99398 14.1668C10.6807 14.1668 13.6673 11.1802 13.6673 7.50016C13.6673 3.82016 10.6807 0.833496 6.99398 0.833496ZM7.00065 12.8335C4.05398 12.8335 1.66732 10.4468 1.66732 7.50016C1.66732 4.5535 4.05398 2.16683 7.00065 2.16683C9.94732 2.16683 12.334 4.5535 12.334 7.50016C12.334 10.4468 9.94732 12.8335 7.00065 12.8335Z"
        fill="#FF3939"
      />
    </Svg>
  )
}

export default Icon
