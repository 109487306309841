import React from 'react'
import Svg from '../../Svg'
import { SvgProps } from '../../types'

const Icon: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="64" height="64" viewBox="0 0 64 64" fill="none" {...props}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1157 0.168823L27.9155 6.56873C28.4575 6.83972 28.7998 7.39392 28.7998 7.99988V23.9999C28.8003 24.6058 28.458 25.16 27.9155 25.431L15.1157 31.8309C14.665 32.056 14.1348 32.056 13.6846 31.8309L0.884277 25.431C0.342284 25.16 0 24.6058 0 23.9999V7.99988C0 7.39392 0.342284 6.83972 0.884277 6.56873L13.6846 0.168823C14.1348 -0.0562744 14.665 -0.0562744 15.1157 0.168823ZM3.20019 23.0106L12.8003 27.8109V15.389L3.20019 10.5887V23.0106ZM5.17822 7.99988L14.3999 12.6107L23.6221 7.99988L14.3999 3.38904L5.17822 7.99988ZM16 27.8109L25.6001 23.0106V10.5887L16 15.389V27.8109ZM48 16.0004H36.8003C35.9165 16.0004 35.2002 15.2841 35.2002 14.4003C35.2002 13.517 35.9165 12.8007 36.7998 12.8007H49.6001C50.4834 12.8007 51.1997 13.517 51.1997 14.4003V24.0004C51.1997 24.8842 50.4834 25.6005 49.6001 25.6005C48.7163 25.6005 48 24.8842 48 24.0004V16.0004ZM27.1997 48.0004H15.9995V40.0004C15.9995 39.1166 15.2832 38.4003 14.3994 38.4003C13.5161 38.4003 12.7998 39.1166 12.7998 40.0004V49.6005C12.7998 50.4838 13.5161 51.2001 14.3994 51.2001H27.1997C28.083 51.2001 28.7993 50.4838 28.7993 49.6005C28.7993 48.7167 28.083 48.0004 27.1997 48.0004ZM50.3154 32.1688L63.1157 38.5687C63.6577 38.8397 64 39.3939 64 39.9999V55.9999C64 56.6058 63.6577 57.16 63.1157 57.431L50.3154 63.8309C49.8652 64.056 49.335 64.056 48.8843 63.8309L36.0845 57.431C35.5425 57.16 35.2002 56.6058 35.2002 55.9999V39.9999C35.1997 39.3939 35.542 38.8397 36.0845 38.5687L48.8843 32.1688C49.335 31.9437 49.8652 31.9437 50.3154 32.1688ZM38.3999 55.0106L48 59.8109V47.389L38.3999 42.5887V55.0106ZM40.3779 39.9999L49.6001 44.6107L58.8218 39.9999L49.6001 35.389L40.3779 39.9999ZM51.1997 59.8109L60.7998 55.0106V42.5887L51.1997 47.389V59.8109Z" fill="url(#paint0_linear_4483_207097)" />
            <defs>
                <linearGradient id="paint0_linear_4483_207097" x1="10.074" y1="8.98765" x2="54.7159" y2="52.2469" gradientUnits="userSpaceOnUse">
                    <stop offset="0.0729167" stop-color="#513CFF" />
                    <stop offset="1" stop-color="#23ABD4" />
                </linearGradient>
            </defs>
        </Svg>
    )
}

export default Icon
