import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 22 13" {...props}>
     <path d="M12.3896 6.70802C12.3896 10.0419 9.63919 12.749 6.23192 12.749C2.82466 12.749 0.0742188 10.0419 0.0742188 6.70802C0.0742188 3.37412 2.82466 0.666992 6.23192 0.666992C9.63919 0.666992 12.3896 3.37412 12.3896 6.70802ZM19.1357 6.70802C19.1357 9.84188 17.7537 12.389 16.0569 12.389C14.3601 12.389 12.978 9.84188 12.978 6.70802C12.978 3.57415 14.3601 1.02705 16.0569 1.02705C17.7537 1.02705 19.1357 3.56082 19.1357 6.70802ZM21.8999 6.70802C21.8999 9.52183 21.4209 11.8022 20.8188 11.8022C20.2168 11.8022 19.7378 9.52183 19.7378 6.70802C19.7378 3.89421 20.2168 1.61382 20.8188 1.61382C21.4209 1.61382 21.8999 3.89421 21.8999 6.70802Z" fill="url(#paint0_linear_8877_17496)"/>
<defs>
<linearGradient id="paint0_linear_8877_17496" x1="1.05358" y1="1.67383" x2="11.0511" y2="19.0066" gradientUnits="userSpaceOnUse">
<stop offset="0.0729167" stop-color="#513CFF"/>
<stop offset="1" stop-color="#23ABD4"/>
</linearGradient>
</defs>
    </Svg>
  )
}



export default Icon
