import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 48 48" {...props}>
      <path
        d="M32.1476 18.609C31.5571 18.261 30.7896 18.261 30.1403 18.609L25.535 21.2767L22.4059 23.0166L17.8007 25.6843C17.2103 26.0322 16.4428 26.0322 15.7934 25.6843L12.1328 23.5965C11.5424 23.2485 11.1292 22.6106 11.1292 21.9147V17.7971C11.1292 17.1011 11.4834 16.4632 12.1328 16.1152L15.7343 14.0854C16.3247 13.7375 17.0922 13.7375 17.7417 14.0854L21.3432 16.1152C21.9335 16.4632 22.3469 17.1011 22.3469 17.7971V20.4648L25.476 18.667V15.9993C25.476 15.3033 25.1218 14.6654 24.4723 14.3174L17.8007 10.4898C17.2103 10.1418 16.4428 10.1418 15.7934 10.4898L9.00369 14.3174C8.35424 14.6654 8 15.3033 8 15.9993V23.7125C8 24.4084 8.35424 25.0464 9.00369 25.3944L15.7934 29.222C16.3837 29.5699 17.1513 29.5699 17.8007 29.222L22.4059 26.6122L25.535 24.8144L30.1403 22.2046C30.7306 21.8567 31.4981 21.8567 32.1476 22.2046L35.7491 24.2344C36.3394 24.5824 36.7527 25.2204 36.7527 25.9163V30.0338C36.7527 30.7299 36.3985 31.3677 35.7491 31.7157L32.1476 33.8035C31.5571 34.1515 30.7896 34.1515 30.1403 33.8035L26.5388 31.7737C25.9483 31.4258 25.535 30.7878 25.535 30.0919V27.4242L22.4059 29.222V31.8897C22.4059 32.5856 22.7602 33.2236 23.4095 33.5715L30.1993 37.3991C30.7896 37.7471 31.5571 37.7471 32.2066 37.3991L38.9963 33.5715C39.5867 33.2236 40 32.5856 40 31.8897V24.1764C40 23.4805 39.6457 22.8426 38.9963 22.4947L32.1476 18.609Z"
        fill="white"
      />
    </Svg>
  )
}

export default Icon
