import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 44 44" {...props}>
      <path
        d="M35.475 18.4066C34.2283 12.0816 28.6733 7.33331 22 7.33331C16.7017 7.33331 12.1 10.34 9.80833 14.74C4.29 15.3266 0 20.0016 0 25.6666C0 31.735 4.93167 36.6666 11 36.6666H34.8333C39.8933 36.6666 44 32.56 44 27.5C44 22.66 40.2417 18.7366 35.475 18.4066ZM34.8333 33H11C6.94833 33 3.66667 29.7183 3.66667 25.6666C3.66667 21.9083 6.47167 18.7733 10.1933 18.3883L12.155 18.1866L13.0717 16.445C14.8133 13.09 18.2233 11 22 11C26.8033 11 30.9467 14.41 31.8817 19.1216L32.4317 21.8716L35.2367 22.0733C38.0967 22.2566 40.3333 24.6583 40.3333 27.5C40.3333 30.525 37.8583 33 34.8333 33ZM14.6667 23.8333H19.3417V29.3333H24.6583V23.8333H29.3333L22 16.5L14.6667 23.8333Z"
        fill="url(#paint0_linear_572_13848)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_572_13848"
          x1="1.97437"
          y1="9.77775"
          x2="28.2515"
          y2="47.6062"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0729167" stopColor="#513CFF" />
          <stop offset="1" stopColor="#23ABD4" />
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default Icon
