import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 48 48" {...props}>
      <path
        d="M13.2004 30.3211C13.3963 30.1339 13.6617 30.0287 13.9385 30.0287H39.4769C39.9421 30.0287 40.175 30.5671 39.8459 30.8818L34.7996 35.7076C34.6038 35.8948 34.3383 36 34.0615 36H8.52312C8.05793 36 7.82502 35.4616 8.15408 35.1468L13.2004 30.3211Z"
        fill="white"
      />
      <path
        d="M13.2004 12.2924C13.3962 12.1052 13.6617 12 13.9385 12H39.4769C39.9421 12 40.175 12.5384 39.8459 12.8532L34.7996 17.6789C34.6038 17.8661 34.3383 17.9713 34.0615 17.9713H8.52312C8.05793 17.9713 7.82502 17.4329 8.15408 17.1182L13.2004 12.2924Z"
        fill="white"
      />
      <path
        d="M34.7996 21.2493C34.6038 21.0621 34.3383 20.957 34.0615 20.957H8.52312C8.05793 20.957 7.82502 21.4954 8.15408 21.8101L13.2004 26.6358C13.3962 26.8231 13.6617 26.9283 13.9385 26.9282H39.4769C39.9421 26.9282 40.175 26.3898 39.8459 26.0751L34.7996 21.2493Z"
        fill="white"
      />
    </Svg>
  )
}

export default Icon
