import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 18" {...props}>
      <path d="M1.89824 7.90514C1.89824 7.90514 10.3225 4.32117 13.2442 3.05917C14.3642 2.55441 18.1624 0.939042 18.1624 0.939042C18.1624 0.939042 19.9155 0.232379 19.7694 1.94863C19.7206 2.65536 19.3311 5.12879 18.9416 7.80419C18.3572 11.5901 17.7242 15.7294 17.7242 15.7294C17.7242 15.7294 17.6268 16.8904 16.799 17.0923C15.9712 17.2942 14.6076 16.3857 14.3642 16.1837C14.1694 16.0323 10.7121 13.7607 9.44599 12.6502C9.10511 12.3473 8.71557 11.7416 9.49465 11.0349C11.2477 9.36905 13.3416 7.29943 14.6076 5.98699C15.192 5.3812 15.7763 3.96781 13.3416 5.68406C9.88426 8.15755 6.47559 10.4796 6.47559 10.4796C6.47559 10.4796 5.69645 10.9843 4.23561 10.53C2.7747 10.0758 1.07037 9.47 1.07037 9.47C1.07037 9.47 -0.0982452 8.71283 1.89824 7.90514Z" fill="url(#paint0_linear_8877_17483)"/>
<defs>
<linearGradient id="paint0_linear_8877_17483" x1="1.58517" y1="2.15546" x2="17.093" y2="19.5194" gradientUnits="userSpaceOnUse">
<stop offset="0.0729167" stop-color="#513CFF"/>
<stop offset="1" stop-color="#23ABD4"/>
</linearGradient>
</defs>
    </Svg>
  )
}


export default Icon
